import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

class ActivitiesPage extends Component {
  componentDidMount() {
    //
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bagoly-Lak - Szabadidős programok</title>
        </Helmet>
        <div className="main">
          <img className="main__background-full" src="assets/background-full.png" alt="Háttérkép" />
          <img
            className="main__background-minimal"
            src="assets/background-minimal.png"
            alt="Háttérkép"
          />
          <img className="main__logo" src="assets/bagoly-lak-logo.png" alt="Logo" />
          <div className="main__menu main__menu--desktop">
            <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
            <div className="main__menu__menus">
              <Link to="/">Bemutató</Link>
              <Link to="/matraszentimre">Mátraszentimre</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/hasznos-informaciok">Hasznos információk</Link>
              <Link to="/szabadidos-programok">Szabadidős programok</Link>
              <Link to="/elerhetoseg">Elérhetőség</Link>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="main__menu main__menu--mobile">
                  <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
                  <div className="main__menu__menus">
                    <Link to="/">Bemutató</Link>
                    <Link to="/matraszentimre">Mátraszentimre</Link>
                    <Link to="/galeria">Galéria</Link>
                    <Link to="/hasznos-informaciok">Hasznos információk</Link>
                    <Link to="/szabadidos-programok">Szabadidős programok</Link>
                    <Link to="/elerhetoseg">Elérhetőség</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="activities">
                    <h1>Szabadidős programok</h1>

                    <ul>
                      <h3>Várak, kirándulóhelyek a közelben</h3>
                      <li>
                        A siroki vár honlapja/nyitva tartással, árakkal az alábbi linken érhető el:{' '}
                        <a
                          className="link"
                          href="http://www.sirokivar.hu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.sirokivar.hu/
                        </a>
                      </li>
                      <li>
                        Bodonyi kincsesház:{' '}
                        <a
                          className="link"
                          href="http://www.bodony.hu/?action=regio&id=3&pageIdx=11"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.bodony.hu/?action=regio&id=3&pageIdx=11
                        </a>
                      </li>
                      <li>
                        Parádsasvári üvegmanufaktúra:{' '}
                        <a
                          className="link"
                          href="http://www.artglassparad.hu/latogatoinknak/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.artglassparad.hu/latogatoinknak/
                        </a>
                      </li>
                      <li>
                        Ajánljuk a közeli Kisnánai várat is megtekintésre.{' '}
                        <a
                          className="link"
                          href="http://www.kisnanavar.hu/new/hu/informacio"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.kisnanavar.hu/new/hu/informacio
                        </a>
                      </li>
                      <li>Mátraderecskei Népművészeti Ház, Palóc út Információs központ</li>
                      <li>Parádfürdő, Cifra istálló, Kocsi múzeum (15 km)</li>
                      <li>Parádi Tájház (17 km)</li>
                      <li>Gyöngyös, Mátra múzeum (43 km)</li>
                      <li>Sástó kilátó és tó, csónakázási és vizibiciklizési lehetőséggel</li>
                      <li>Adrenalin Park: canopy, bob, függőhíd</li>
                      <li>
                        Mátrafüred High-Tech sportok bázisa: Segway, Quad, túrázási lehetőségek
                      </li>
                      <li>Gyöngyike kisvasút Gyöngyös és Mátrafüred között</li>
                      <li>Farkasmályi borpincék</li>
                      <li>Mátraszentistván sípark</li>
                      <li>Kékestető: kilátás Magyarország tetejéről a TV toronyból</li>
                      <li>Galyatető: kilátó és turisztikai központ</li>
                      <li>
                        A „kéktúra” útvonalon lehet jó néhány km-t megtenni és pecsételtetni. Sok
                        egyéb erdei túrázási lehetőség
                      </li>
                    </ul>

                    <ul>
                      <h3>Magyarország legjobb levegőjű térségében</h3>
                      <li>A szomszédos település Fallóskút, ami nevezetes zarándokhely</li>
                      <li>Parádsasvár: üveggyár megtekintése</li>
                      <li>Recski múzeum.</li>
                    </ul>

                    <ul>
                      <h3>Fürdők a közelben</h3>
                      <li>Bükkszékfürdő</li>
                      <li>Egri török fürdő</li>
                      <li>Demjéni Termál Völgy</li>
                      <li>Egerszalóki Gyógy- és Wellnessfürdő - Saliris Spa és Konferencia</li>
                      <li>Parádon fürdő, és a parádi gyógyvíz kútja (gyomorbántalmakra)</li>
                    </ul>

                    <ul>
                      <h3>Aktív pihenést kedvelőknek</h3>
                      <li>OXIGEN ADRENALIN PARK Mátrafüred, Sástó</li>
                      <li>GYERMEKVÁR Játszóház és Kalandpark, Eger</li>
                      <li>ÖKO-PARK KALANDPARK, Szarvaskő</li>
                      <li>Kalanderdő és outdoor programok a Bükkben</li>
                      <li>SZÓRAKO-ZOO állatkert, Gyöngyös</li>
                      <li>NORDIC WALKING a Mátrában</li>
                      <li>GOKART LAND a Mátra lábánál</li>
                      <li>Fedémesi Siklóernyős Park, Fedémes</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main__separator" />
        </div>
      </>
    );
  }
}

export default ActivitiesPage;
